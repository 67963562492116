import React, { ReactElement, useEffect, useState } from "react"
import SEO from "../../components/SEO"
import Button from "../../components/design-system/Button"
import TextField from "../../components/design-system/TextField"
import T from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import { styled } from "@mui/material/styles"
import Link from "../../components/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import { useForm } from "react-hook-form"
import { useUser } from "../../providers/UserProvider"
import { navigate } from "gatsby"
import MuiCheckCircleOutline from "@mui/icons-material/CheckCircleOutline"
import { FirebaseError } from "firebase/app"
import validationMessages from "../../data/signInValidationMessages"
import { email } from "@ap-frontend/questions"
import NoScript from "../../components/NoScript"
import Container from "@mui/material/Container"
import ButtonWrapper from "../../components/ButtonWrapper"

const Form = styled("form")()

const CheckCircleOutline = styled(MuiCheckCircleOutline)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const BoldEmailText = styled("strong")(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.primary.dark,
}))

const EmailAdviceBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: theme.palette.grey[100],
  maxWidth: "600px",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))

interface FormData {
  email: string
}

interface ResetPasswordProps {
  mode?: "activate" | "forgot"
}

export const ResetPassword = ({
  mode = "activate",
}: ResetPasswordProps): ReactElement => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()
  const [emailSent, setEmailSent] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { handleSubmit, register, formState } = useForm<FormData>()
  const { user, sendPasswordResetEmail } = ResetPassword.useUser()

  // This will only run if the page has been hydrated with JS
  // If JS is disabled, this will never run
  useEffect(() => {
    setIsLoading(false)
  }, [isLoading, setIsLoading])

  if (isLoading) {
    return (
      <Container component="main" sx={{ mt: 6 }}>
        <NoScript />
      </Container>
    )
  }

  if (user) {
    // Already authenticated
    navigate("/application", {
      replace: true,
    })

    return <></>
  }

  if (emailSent) {
    return (
      <>
        <T variant="h1" component="h1" data-test-id="email-sent-header">
          <CheckCircleOutline fontSize="large" />
          Email sent
        </T>
        <T variant="body1">
          We sent an email to <BoldEmailText>{emailSent}</BoldEmailText>.<br />
          Use the link in the email to create a new password for your account.
        </T>
        <EmailAdviceBox>
          <T variant="body1">{"If you haven't received an email:"}</T>
          <List className="MuiList-unordered" component="ul">
            <ListItem>
              <T component="span" variant="body1">
                allow a few moments for the email to arrive
              </T>
            </ListItem>
            <ListItem>
              <T component="span" variant="body1">
                {"check your 'junk' folder"}
              </T>
            </ListItem>
            <ListItem>
              <T component="span" variant="body1">
                check that the email address is the address that you used in
                your UCAS application
              </T>
            </ListItem>
          </List>
        </EmailAdviceBox>
        <T variant="body1">
          If you still have not received an email,{" "}
          <Link component="button" onClick={() => setEmailSent(undefined)}>
            request a new link
          </Link>
        </T>
        <T variant="body1">
          Email <Link href={`mailto:${email}`}>{email}</Link> if {"you're "}
          still having problems
        </T>
      </>
    )
  }

  const handleSendActivationEmail = async (data: FormData) => {
    setIsProcessing(true)
    setError(undefined)
    try {
      await sendPasswordResetEmail(data.email.trim())

      setEmailSent(data.email.trim())
    } catch (e) {
      if (e instanceof FirebaseError && e.code) {
        setError(e.code)
      } else {
        setError("unknown")
      }
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <>
      <T variant="h1" component="h1">
        {mode === "activate" ? "Activate your account" : "Reset your password"}
      </T>
      <T variant="body1">
        {`Enter your email address and we'll send you a link to ${
          mode === "forgot" ? "create a new" : "create your"
        } password.`}
      </T>
      <Form onSubmit={handleSubmit(handleSendActivationEmail)}>
        {error ? (
          <Alert severity="error" data-test-alert="activation-error">
            <T variant="body1" component="div">
              {error in validationMessages
                ? validationMessages[error]
                : "There is a problem. Try again later."}
            </T>
          </Alert>
        ) : undefined}
        <TextField
          label="Email"
          id="email"
          name="email"
          hint="This is the address that you used in your UCAS application."
          characterWidth={45}
          inputProps={{
            inputMode: "email",
            ...register("email", {
              required: "Enter your email address.",
              pattern: {
                value: /^\s*\S+@\S+\s*$/,
                message: "Enter a valid email address.",
              },
            }),
          }}
          feedback={formState.errors.email?.message}
          data-test-question="email-field"
        />
        <ButtonWrapper>
          <Button
            type="submit"
            data-test-button="send-activate-btn"
            disabled={isProcessing}
          >
            Send link
          </Button>
        </ButtonWrapper>
      </Form>
      <T variant="body2">
        {mode === "activate"
          ? "If you have activated your account, "
          : "If you do not want to reset your password, "}
        <Link to="/sign-in">sign in</Link>.
      </T>
    </>
  )
}

// Setting these as static members allows us to mock during component testing
ResetPassword.useUser = useUser

export default function ActivateAccountPage(): React.ReactNode {
  return (
    <>
      <SEO title="Activate your account" />
      <ResetPassword />
    </>
  )
}
