const validationMessages: { [key: string]: string } = {
  "auth/user-not-found":
    "Enter the email address you used in your UCAS application.",
  "auth/wrong-password":
    "Your password is incorrect. Try again or reset your password.",
  "auth/network-request-failed": "Check your network connection and try again.",
  "auth/reset_password_exceed_limit":
    "Use the link in the email to create a password.",
  "auth/user-disabled":
    "The account linked to this email address has been closed.",
  "auth/invalid-verification-code":
    "The verification code entered is incorrect.",
  "auth/code-expired": "The verification code has expired.",
}

export default validationMessages
